import React from 'react';

export default class LogoutLogo extends React.Component {
  render() {
    return (
      <React.Fragment>
        <a href='/users/sign_out' data-method='delete'>
          <img src={`${gon.HUBSPOT_ASSETS_URL}/oomple/assets/app/v5/layout/nav_logo.png`} className='height-28 margin-top-14 margin-left-28' />
        </a>
      </React.Fragment>
    )
  }
}