import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classNames from 'classnames';

// Mixins / Modules
import { deparam } from '../../../../modules/global';
import isResizable from '../../../mixins/resizable';
import Window from '../../../../modules/window';

// Models
import User from '../../../../models/v2/user';

// Base
import Button from '../../base/button';
import GoogleButton from '../../base/google_button';
import Separator from '../../base/separator';

// Form
import Checkbox from '../../form/checkbox';
import Input from '../../form/input';
import Testimonials from './testimonials';

// Related Components
import LogoutLogo from '../../agents/subscribe/logout_logo';

class SignIn extends React.Component {
  static propTypes = {
    onSignIn: PropTypes.func,
  }

  constructor(props) {
    super(props);

    const params = deparam(location.search.slice(1));
    this.state = {
      sending: false,
      user: {
        email: params.email || '',
      },
    }
  }

  get mainClassName() {
    const { isDesktop } = this.props;
    return classNames('', {
      'width-60pct': isDesktop,
      'width-100pct': !isDesktop,
    });
  }

  get sidebarClassName() {
    const { isDesktop } = this.props;
    return classNames('secondary-background', {
      'fixed right-0 top-0 height-100vh width-40pct': isDesktop,
      'width-100%': !isDesktop,
    });
  }

  handleInputChange = (field, value) => {
    const { user } = this.state;
    const newUser = update(user, { [field]: { $set: value } });
    this.setState({ user: newUser });
  }

  handleSubmit = (e) => {
    const { onSignIn } = this.props;
    const { sending, user } = this.state;

    e && e.preventDefault();

    if (sending) return;

    this.setState({ sending: true }, () => {
      $.ajax({
        url: '/users/sign_in.json',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          user,
        }),
        success: (response) => {
          if (onSignIn === undefined) {
            Window.redirect(response.location);
          } else {
            onSignIn(response.location, response.user);
          }
        },
        error: (errors) => {
          const { responseJSON } = errors;
          const message = (responseJSON && responseJSON.error) ? responseJSON.error : 'Invalid email or password';
          Window.flash.error(errors, message);
          this.setState({ sending: false });
        },
      });
    })
  }

  handleGoogleAuth = (response) => {
    const { user } = response;
    if (user && !user.user_type) {
      this.setState({
        showUserType: true,
        user: response.user,
        response,
      })
    } else {
      this.handleRedirect(response.location);
    }
  }

  // Redirect to either the requested location or back to the root path
  handleRedirect = (location) => {
    const { response } = this.state;
    // Take what's passed in, if not, then what's stored in state
    // and finally fall back to root path
    const redirectTo = location || (response || {}).location || '/';
    Window.redirect(redirectTo);
  }

  handleChangeUserType = (userType) => {
    const { user } = this.state;
    const newUser = update(user, { $merge: { user_type: userType } });
    this.setState({ user: newUser });
  }

  handleNext = () => {
    const { user } = this.state;
    const data = {
      id: user.id,
      user_type: user.user_type,
    };
    (new User(data))
      .save()
      .then(() => this.handleRedirect())
      .catch ((error) => Window.flash.error(error));
  }

  render() {
    const { user, showUserType, sending } = this.state;

    if(showUserType) {
      return <UserType {...{
        user,
        onChangeUserType: this.handleChangeUserType,
        onNext: this.handleNext,
      }} />
    }

    return (
      <React.Fragment>
        <div className={this.mainClassName}>
          <div className='height-60 margin-bottom-70'>
            <LogoutLogo />
          </div>
          <div className='width-560 margin-center'>
            <img src={`${gon.HUBSPOT_ASSETS_URL}/oomple/assets/app/v5/layout/logo_square.png`} className='margin-bottom-24' />
            <div className='margin-bottom-10 xlg-font semi-bold line-height-26'>Login to Your Oomple Account</div>
            <Separator className='margin-top-0 margin-bottom-30' />
            <div className='width-520'>
              <div className='margin-bottom-20'>
              </div>
              <div className='margin-bottom-20 width-100pct flex'>
                <div className='margin-right-auto margin-left-auto'>
                  <GoogleButton {...{
                    onAuth: this.handleGoogleAuth,
                  }} />
                </div>
              </div>
              <div className='flex justify-space-between vertical-align margin-bottom-20'>
                <div><Separator className='width-155 margin-top-14 margin-bottom-14' /></div>
                <div>or</div>
                <div><Separator className='width-155 margin-top-14 margin-bottom-14' /></div>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div>
                  <Input {...{
                    id: 'user_email',
                    label: 'Email address',
                    placeholder: 'isabella@email.com',
                    type: 'email',
                    onChange: (_, value) => this.handleInputChange('email', value),
                    defaultValue: user.email,
                  }} />
                </div>
                <div className='margin-bottom-45'>
                  <Input {...{
                    id: 'user_password',
                    label: 'Password',
                    placeholder: 'xxxxxx',
                    type: 'password',
                    className: 'margin-bottom-15',
                    onChange: (_, value) => this.handleInputChange('password', value),
                    allowPasswordToggle: true,
                  }} />
                  <div className='flex justify-space-between'>
                    <Button {...{
                      to: '/users/password/new',
                      className: 'xsm-font margin-bottom-5 block',
                      text: 'Forgot Password?',
                      size: 'custom',
                    }} />
                    <Button {...{
                      to: '/users/confirmation',
                      className: 'xsm-font',
                      text: 'Resend Confirmation',
                      size: 'custom',
                    }} />
                  </div>
                </div>
                <div className='flex-grow-1'>
                  <Checkbox {...{
                    id: 'user_remember_me',
                    label: 'Remember me',
                    onChange: (_, checked) => this.handleInputChange('remember_me', checked),
                  }} />
                </div>
                <div>
                  <Button {...{
                    id: 'sign_in',
                    text: 'Log In',
                    onClick: this.handleSubmit,
                    className: 'height-48 med-font vertical-align line-height-45',
                    disabled: sending,
                    block: true,
                  }} />
                </div>
                <div className='margin-top-30 sm-font'>
                  Don't have an account? <Button {...{
                    to: '/users/sign_up',
                    className: 'sm-font margin-top-30 underline',
                    text: 'Sign up',
                    size: 'custom',
                  }} />
                </div>
                <button type='submit' className='hide' />
              </form>
            </div>
          </div>
        </div>
        <div className={this.sidebarClassName}>
          <div className='margin-center'>
            <Testimonials />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default isResizable(SignIn);