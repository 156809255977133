import React from 'react';
import PropTypes from 'prop-types';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

// Modules
import Window from '../../../modules/window';

// Models
import GoogleSignin from '../../../models/internal/google_signin';

export default class GoogleButton extends React.Component {
  static propTypes = {
    width: PropTypes.string,
    shape: PropTypes.string,
    access: PropTypes.string,
    theme: PropTypes.string,
    type: PropTypes.string,
    logo_alignment: PropTypes.string,
    context: PropTypes.string,
    text: PropTypes.string,
    userType: PropTypes.string,
    planName: PropTypes.string,
    planDuration: PropTypes.string,
    onAuth: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    onError: PropTypes.func,
    opportunityId: PropTypes.number,
  }

  static defaultProps = {
    width: '400',
    shape: 'rectangular',
    access: 'offline',
    theme: 'outline',
    type: 'standard',
    logo_alignment: 'left',
    context: 'signin',
    text: 'signin_with',
    onValidate: () => { },
    onError: () => { },
  }

  render() {
    const { width, shape, access, theme, type, logo_alignment, context, text, onAuth, onValidate, onError, userType, opportunityId, planName, planDuration } = this.props;
    return (
      <GoogleOAuthProvider clientId={gon.GOOGLE_CLIENT_ID}>
      {
        <GoogleLogin {...{
          shape,
          access,
          width,
          theme,
          type,
          logo_alignment,
          context,
          text,
          onSuccess: (response) => {
            const data = {
              credential: response.credential,
              user_type: userType,
              plan_name: planName,
              plan_duration: planDuration,
            };
            // After validating from google, but before
            // signing in/up
            onValidate();
            (new GoogleSignin(data))
              .params({ opportunity_id: opportunityId })
              .save()
              .then((response) => {
                onAuth(response);
              })
              .catch((error) => {
                onError();
                Window.flash.error(error);
              });
          },
          onError: (error) => {
            Window.flash.error(error);
          }
        }} />
      }
      </GoogleOAuthProvider>
    )
  }
}